import { Col } from "react-bootstrap";
import dReadsLogo from "../../assets/icons/dReadsLogo.svg";
import leftbgCenter from "../../assets/images/leftbgcenter.svg";

const LeftColoumn = () => {
  return (
    <>
      <div className="position-fixed z-3 social-logo mb-2">
        <img className="py-3 ml-2" src={dReadsLogo} width={127} alt="dsocial logo" />
      </div>
      <Col xl={5} className="left-col position-relative">
        <div className="position-absolute ministry-wrapper translate-middle">
          <img src={leftbgCenter} width={359} alt="l1fe ministy" className="center-logo" />
        </div>
      </Col>
    </>
  )
}

export default LeftColoumn